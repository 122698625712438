import React, { useEffect, useState, useMemo } from "react";
import { useTable, useFilters } from "react-table";
import Select from "react-select";
import './table-styles.css';

const App = () => {
  const [data, setData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL =
    "https://api.rankedin.com/v1/Organization/GetOrganisationEventsAsync?organisationId=1420&IsFinished=false&Language=en&skip=0&take=100&_=1732358524694&TournamentClassTerm=dpf25&eventStateId=1";

  const preselectedCities = ["Ballerup", "Taastrup", "Ringsted","Gadstrup","Brøndby","København S"]; // Example cities

  // Date formatter function
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("da-DK", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(date);
    } catch {
      return "Invalid Date";
    }
  };

  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((result) => {
        const events = result.payload || [];
        setData(events);

        const uniqueCities = [
          ...new Set(events.map((item) => item.city).filter(Boolean)),
        ]
          .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
          .map((city) => ({ value: city, label: city }));

        setFilteredCities(uniqueCities);

        // Initialize preselected cities
        const initialSelected = uniqueCities.filter((city) =>
          preselectedCities.includes(city.value)
        );
        setSelectedCities(initialSelected);

        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to fetch data");
        setLoading(false);
      });
  }, []);

  // Memoize columns
  const columns = useMemo(
    () => [
      {
        Header: "Event Name",
        accessor: "eventName",
      },
      {
        Header: "Club",
        accessor: "club",
      },
      {
        Header: "City",
        accessor: "city",
        Filter: ({ column }) => (
          <Select
            isMulti
            options={filteredCities}
            value={selectedCities} // Preselect cities
            onChange={(selected) => {
              const selectedValues = selected ? selected.map((item) => item.value) : [];
              setSelectedCities(selected); // Update selected cities state
              column.setFilter(selectedValues.length ? selectedValues : undefined);
            }}
            isClearable
            closeMenuOnSelect={false}
            styles={{
              control: (base) => ({
                ...base,
                fontSize: "14px",
                minWidth: "100%",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
          />
        ),
        filter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) return rows;
          return rows.filter((row) =>
            filterValue.includes(row.values.city)
          );
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Join URL",
        accessor: "joinUrl",
        Cell: ({ value }) => (
          <a
            href={`https://rankedin.com${value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join
          </a>
        ),
      },
    ],
    [filteredCities, selectedCities]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: () => null },
    },
    useFilters
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    // Apply initial filter when selectedCities is populated
    if (selectedCities.length > 0) {
      const selectedValues = selectedCities.map((city) => city.value);
      tableInstance.setFilter("city", selectedValues);
    }
  }, [selectedCities, tableInstance]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Organization Events</h1>
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="cityFilter" style={{ marginRight: "10px", fontWeight: "bold" }}>
          Filter by City:
        </label>
        <Select
          id="cityFilter"
          isMulti
          options={filteredCities}
          value={selectedCities} // Preselect cities
          onChange={(selected) => {
            const selectedValues = selected ? selected.map((item) => item.value) : [];
            setSelectedCities(selected); // Update selected cities state
            tableInstance.setFilter("city", selectedValues);
          }}
          isClearable
          closeMenuOnSelect={false}
          styles={{
            control: (base) => ({
              ...base,
              width: "100%",
              maxWidth: "400px",
              fontSize: "14px",
            }),
            menu: (base) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
        />
      </div>
      <table {...getTableProps()} style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
  {rows.map((row) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <td
            {...cell.getCellProps()}
            data-label={cell.column.Header} // Add data-label for mobile
            style={{
              fontWeight: cell.column.Header === "City" ? "bold" : "normal", // Make City bold
            }}
          >
            {cell.render("Cell")}
          </td>
        ))}
      </tr>
    );
  })}
</tbody>
      </table>
    </div>
  );
};

export default App;
